* {
   font-size: 15px;
}

/* $theme-colors: ("primary": #0000aa, "secondary": #ffdc00, "danger": #ff4136); */

/* @import '~bootstrap/scss/bootstrap.scss'; */

.btn-primary {
	background-color: #0000aa;
   border-color: #0000aa;
}

.btn-outline-primary {
   border-color: #0000aa;
   color: #0000aa;
}

.btn-outline-primary:hover {
	background-color: #0000aa;
   border-color: #0000aa;	
}

a {
	color: #0000aa;
}

.text-primary {
	color: #0000aa!important;
}

input.form-control {
   font-size: 1.1em;
}

input.form-control {
   padding: 7px 10px;
   font-size: 1.1rem;
   border-radius: 8px;
   border: 1px solid #ececec;
}

input.form-control:focus,
input.form-control:active {
   outline: none;
   border: 1px solid #ececec;
   -webkit-box-shadow: 0px 0px 5px 0px #e6e6e6;
   -moz-box-shadow: 0px 0px 5px 0px #e6e6e6;
   box-shadow: 0px 0px 5px 0px #e6e6e6;
}

::selection {
   background: #93c5fa;
}

::-moz-selection {
   background: #93c5fa;
}

/**
* LAYOUT CUSTOM CSS
**/
.ant-layout,
.ant-layout-footer {
   background-color: #f8f8f8;
}

.ant-layout-header {
   background-color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
   background-color: #ffdc00;
}

.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
   background-color: #010053;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-layout-sider-trigger {
   background-color: #020133;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
   height: 50px;
   padding-top: 5px;
}

.ant-layout-header {
   padding: 0px 30px;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
   background-color: #ffdc00;
   color: #fff;
}

.ant-menu-submenu-selected {
   color: #fff;
}

/**
* END: Custom Layout
**/

.ant-card {
   margin-bottom: 15px;
}

.light {
   font-weight: 300;
}

.ant-switch-checked {
   background-color: #ffdc00;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
   border-color: #0000aa;
}

.ant-descriptions-row>th,
.ant-descriptions-row>td {
   padding-bottom: 3px;
}

.ant-steps-item-process .ant-steps-item-icon {
   background-color: #0000aa;
   border-color: #0000aa;
}

.ant-steps-item-finish .ant-steps-item-icon {
   background-color: #ffdc00;
   border-color: #ffdc00;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
   color: #fff;
   position: relative;
   top: -3px;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
   background-color: #ffdc00;
}

.btn {
   padding-bottom: 0.4rem;
}

.ant-alert-with-description .ant-alert-icon {
   top: 20px;
}

td.ant-descriptions-item-content {
   background: #fff;
}

.ant-picker:hover,
.ant-picker-focused {
   border-color: #0000aa;
}

.ant-picker {
   line-height: 1.9;
}

.ant-picker-suffix {
   position: relative;
   top: -3px;
}

.btn svg {
   position: relative;
   top: 2px;
   left: 1px;
}

.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:visited,
.ant-btn-primary:focus {
   background-color: #0000aa;
   border-color: #0000aa;
}

.ant-btn-primary:hover {
   background-color: #01017c;
   border-color: #01017c;
   color: #fff !important;
}

.ant-btn:hover {
   border-color: #0000aa;
   color: #0000aa;
}

.filters {
   max-width: 800px;
   margin-bottom: 20px;
}

.input-group-prepend .form-control {
   border-radius: 0;
   height: 38px;
}


body {
   overflow: scroll!important;
}

span.ant-statistic-content-value-int {
   font-size: 30px;
}

.ant-statistic {
   line-height: 1.2;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: #ffdc00;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
   background-color: #010053;
}

.float-right {
   float: right;
}

.form-group {
   margin-bottom: 10px;
}

.ant-menu-title-content a,
.ant-tooltip a {
   text-decoration: none;
}