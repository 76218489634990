.fs-10 {
   font-size: 10px !important
}

.fs-11 {
   font-size: 11px !important
}

.fs-12 {
   font-size: 12px !important
}

.fs-13 {
   font-size: 13px !important
}

.fs-14 {
   font-size: 14px !important
}

.fs-15 {
   font-size: 15px !important
}

.fs-16 {
   font-size: 16px !important
}

.fs-17 {
   font-size: 17px !important
}

.fs-18 {
   font-size: 18px !important
}

.fs-19 {
   font-size: 19px !important
}

.fs-20 {
   font-size: 20px !important
}

.fs-21 {
   font-size: 21px !important
}

.fs-22 {
   font-size: 22px !important
}

.fs-23 {
   font-size: 23px !important
}

.fs-24 {
   font-size: 24px !important
}

.fs-25 {
   font-size: 25px !important
}

.fs-26 {
   font-size: 26px !important
}

.fs-27 {
   font-size: 27px !important
}

.fs-28 {
   font-size: 28px !important
}

.fs-29 {
   font-size: 29px !important
}

.fs-30 {
   font-size: 30px !important
}

.fs-31 {
   font-size: 31px !important
}

.fs-32 {
   font-size: 32px !important
}

.fs-33 {
   font-size: 33px !important
}

.fs-34 {
   font-size: 34px !important
}

.fs-35 {
   font-size: 35px !important
}

.fs-36 {
   font-size: 36px !important
}

.fs-37 {
   font-size: 37px !important
}

.fs-38 {
   font-size: 38px !important
}

.fs-39 {
   font-size: 39px !important
}

.fs-40 {
   font-size: 40px !important
}

.fs-41 {
   font-size: 41px !important
}

.fs-42 {
   font-size: 42px !important
}

.fs-43 {
   font-size: 43px !important
}

.fs-44 {
   font-size: 44px !important
}

.fs-45 {
   font-size: 45px !important
}

.fs-46 {
   font-size: 46px !important
}

.fs-47 {
   font-size: 47px !important
}

.fs-48 {
   font-size: 48px !important
}

.fs-49 {
   font-size: 49px !important
}

.fs-50 {
   font-size: 50px !important
}

.fs-51 {
   font-size: 51px !important
}

.fs-52 {
   font-size: 52px !important
}

.fs-53 {
   font-size: 53px !important
}

.fs-54 {
   font-size: 54px !important
}

.fs-55 {
   font-size: 55px !important
}

.fs-56 {
   font-size: 56px !important
}

.fs-57 {
   font-size: 57px !important
}

.fs-58 {
   font-size: 58px !important
}

.fs-59 {
   font-size: 59px !important
}

.fs-60 {
   font-size: 60px !important
}

.fs-61 {
   font-size: 61px !important
}

.fs-62 {
   font-size: 62px !important
}

.fs-63 {
   font-size: 63px !important
}

.fs-64 {
   font-size: 64px !important
}

.fs-65 {
   font-size: 65px !important
}

.fs-66 {
   font-size: 66px !important
}

.fs-67 {
   font-size: 67px !important
}

.fs-68 {
   font-size: 68px !important
}

.fs-69 {
   font-size: 69px !important
}

.p-0 {
   padding: 0 !important
}

.p-1 {
   padding: 1px !important
}

.p-2 {
   padding: 2px !important
}

.p-3 {
   padding: 3px !important
}

.p-4 {
   padding: 4px !important
}

.p-5 {
   padding: 5px !important
}

.p-6 {
   padding: 6px !important
}

.p-7 {
   padding: 7px !important
}

.p-8 {
   padding: 8px !important
}

.p-9 {
   padding: 9px !important
}

.p-10 {
   padding: 10px !important
}

.p-11 {
   padding: 11px !important
}

.p-12 {
   padding: 12px !important
}

.p-13 {
   padding: 13px !important
}

.p-14 {
   padding: 14px !important
}

.p-15 {
   padding: 15px !important
}

.p-16 {
   padding: 16px !important
}

.p-17 {
   padding: 17px !important
}

.p-18 {
   padding: 18px !important
}

.p-19 {
   padding: 19px !important
}

.p-20 {
   padding: 20px !important
}

.p-21 {
   padding: 21px !important
}

.p-22 {
   padding: 22px !important
}

.p-23 {
   padding: 23px !important
}

.p-24 {
   padding: 24px !important
}

.p-25 {
   padding: 25px !important
}

.p-26 {
   padding: 26px !important
}

.p-27 {
   padding: 27px !important
}

.p-28 {
   padding: 28px !important
}

.p-29 {
   padding: 29px !important
}

.p-30 {
   padding: 30px !important
}

.p-35 {
   padding: 35px !important
}

.p-36 {
   padding: 36px !important
}

.p-37 {
   padding: 37px !important
}

.p-38 {
   padding: 38px !important
}

.p-39 {
   padding: 39px !important
}

.p-40 {
   padding: 40px !important
}

.pt-0 {
   padding-top: 0 !important
}

.pt-1 {
   padding-top: 1px !important
}

.pt-2 {
   padding-top: 2px !important
}

.pt-3 {
   padding-top: 3px !important
}

.pt-4 {
   padding-top: 4px !important
}

.pt-5 {
   padding-top: 5px !important
}

.pt-6 {
   padding-top: 6px !important
}

.pt-7 {
   padding-top: 7px !important
}

.pt-8 {
   padding-top: 8px !important
}

.pt-9 {
   padding-top: 9px !important
}

.pt-10 {
   padding-top: 10px !important
}

.pt-11 {
   padding-top: 11px !important
}

.pt-12 {
   padding-top: 12px !important
}

.pt-13 {
   padding-top: 13px !important
}

.pt-14 {
   padding-top: 14px !important
}

.pt-15 {
   padding-top: 15px !important
}

.pt-16 {
   padding-top: 16px !important
}

.pt-17 {
   padding-top: 17px !important
}

.pt-18 {
   padding-top: 18px !important
}

.pt-19 {
   padding-top: 19px !important
}

.pt-20 {
   padding-top: 20px !important
}

.pt-21 {
   padding-top: 21px !important
}

.pt-22 {
   padding-top: 22px !important
}

.pt-23 {
   padding-top: 23px !important
}

.pt-24 {
   padding-top: 24px !important
}

.pt-25 {
   padding-top: 25px !important
}

.pt-26 {
   padding-top: 26px !important
}

.pt-27 {
   padding-top: 27px !important
}

.pt-28 {
   padding-top: 28px !important
}

.pt-29 {
   padding-top: 29px !important
}

.pt-30 {
   padding-top: 30px !important
}

.pt-35 {
   padding-top: 35px !important
}

.pt-36 {
   padding-top: 36px !important
}

.pt-37 {
   padding-top: 37px !important
}

.pt-38 {
   padding-top: 38px !important
}

.pt-39 {
   padding-top: 39px !important
}

.pt-40 {
   padding-top: 40px !important
}

.pt-50 {
   padding-top: 50px !important
}

.pt-60 {
   padding-top: 60px !important
}

.pt-70 {
   padding-top: 70px !important
}

.pt-80 {
   padding-top: 80px !important
}

.pt-90 {
   padding-top: 90px !important
}

.pt-95 {
   padding-top: 95px !important
}

.pt-100 {
   padding-top: 100px !important
}

.pt-105 {
   padding-top: 105px !important
}

.pt-110 {
   padding-top: 100px !important
}

.pb-0 {
   padding-bottom: 0 !important
}

.pb-1 {
   padding-bottom: 1px !important
}

.pb-2 {
   padding-bottom: 2px !important
}

.pb-3 {
   padding-bottom: 3px !important
}

.pb-4 {
   padding-bottom: 4px !important
}

.pb-5 {
   padding-bottom: 5px !important
}

.pb-6 {
   padding-bottom: 6px !important
}

.pb-7 {
   padding-bottom: 7px !important
}

.pb-8 {
   padding-bottom: 8px !important
}

.pb-9 {
   padding-bottom: 9px !important
}

.pb-10 {
   padding-bottom: 10px !important
}

.pb-11 {
   padding-bottom: 11px !important
}

.pb-12 {
   padding-bottom: 12px !important
}

.pb-13 {
   padding-bottom: 13px !important
}

.pb-14 {
   padding-bottom: 14px !important
}

.pb-15 {
   padding-bottom: 15px !important
}

.pb-16 {
   padding-bottom: 16px !important
}

.pb-17 {
   padding-bottom: 17px !important
}

.pb-18 {
   padding-bottom: 18px !important
}

.pb-19 {
   padding-bottom: 19px !important
}

.pb-20 {
   padding-bottom: 20px !important
}

.pb-21 {
   padding-bottom: 21px !important
}

.pb-22 {
   padding-bottom: 22px !important
}

.pb-23 {
   padding-bottom: 23px !important
}

.pb-24 {
   padding-bottom: 24px !important
}

.pb-25 {
   padding-bottom: 25px !important
}

.pb-26 {
   padding-bottom: 26px !important
}

.pb-27 {
   padding-bottom: 27px !important
}

.pb-28 {
   padding-bottom: 28px !important
}

.pb-29 {
   padding-bottom: 29px !important
}

.pb-30 {
   padding-bottom: 30px !important
}

.pb-35 {
   padding-bottom: 35px !important
}

.pb-36 {
   padding-bottom: 36px !important
}

.pb-37 {
   padding-bottom: 37px !important
}

.pb-38 {
   padding-bottom: 38px !important
}

.pb-39 {
   padding-bottom: 39px !important
}

.pb-40 {
   padding-bottom: 40px !important
}

.pb-50 {
   padding-bottom: 50px !important
}

.pb-60 {
   padding-bottom: 60px !important
}

.pl-0 {
   padding-left: 0 !important
}

.pl-1 {
   padding-left: 1px !important
}

.pl-2 {
   padding-left: 2px !important
}

.pl-3 {
   padding-left: 3px !important
}

.pl-4 {
   padding-left: 4px !important
}

.pl-5 {
   padding-left: 5px !important
}

.pl-6 {
   padding-left: 6px !important
}

.pl-7 {
   padding-left: 7px !important
}

.pl-8 {
   padding-left: 8px !important
}

.pl-9 {
   padding-left: 9px !important
}

.pl-10 {
   padding-left: 10px !important
}

.pl-11 {
   padding-left: 11px !important
}

.pl-12 {
   padding-left: 12px !important
}

.pl-13 {
   padding-left: 13px !important
}

.pl-14 {
   padding-left: 14px !important
}

.pl-15 {
   padding-left: 15px !important
}

.pl-16 {
   padding-left: 16px !important
}

.pl-17 {
   padding-left: 17px !important
}

.pl-18 {
   padding-left: 18px !important
}

.pl-19 {
   padding-left: 19px !important
}

.pl-20 {
   padding-left: 20px !important
}

.pl-21 {
   padding-left: 21px !important
}

.pl-22 {
   padding-left: 22px !important
}

.pl-23 {
   padding-left: 23px !important
}

.pl-24 {
   padding-left: 24px !important
}

.pl-25 {
   padding-left: 25px !important
}

.pl-26 {
   padding-left: 26px !important
}

.pl-27 {
   padding-left: 27px !important
}

.pl-28 {
   padding-left: 28px !important
}

.pl-29 {
   padding-left: 29px !important
}

.pl-30 {
   padding-left: 30px !important
}

.pl-35 {
   padding-left: 35px !important
}

.pl-36 {
   padding-left: 36px !important
}

.pl-37 {
   padding-left: 37px !important
}

.pl-38 {
   padding-left: 38px !important
}

.pl-39 {
   padding-left: 39px !important
}

.pl-40 {
   padding-left: 40px !important
}

.pr-0 {
   padding-right: 0 !important
}

.pr-1 {
   padding-right: 1px !important
}

.pr-2 {
   padding-right: 2px !important
}

.pr-3 {
   padding-right: 3px !important
}

.pr-4 {
   padding-right: 4px !important
}

.pr-5 {
   padding-right: 5px !important
}

.pr-6 {
   padding-right: 6px !important
}

.pr-7 {
   padding-right: 7px !important
}

.pr-8 {
   padding-right: 8px !important
}

.pr-9 {
   padding-right: 9px !important
}

.pr-10 {
   padding-right: 10px !important
}

.pr-11 {
   padding-right: 11px !important
}

.pr-12 {
   padding-right: 12px !important
}

.pr-13 {
   padding-right: 13px !important
}

.pr-14 {
   padding-right: 14px !important
}

.pr-15 {
   padding-right: 15px !important
}

.pr-16 {
   padding-right: 16px !important
}

.pr-17 {
   padding-right: 17px !important
}

.pr-18 {
   padding-right: 18px !important
}

.pr-19 {
   padding-right: 19px !important
}

.pr-20 {
   padding-right: 20px !important
}

.pr-21 {
   padding-right: 21px !important
}

.pr-22 {
   padding-right: 22px !important
}

.pr-23 {
   padding-right: 23px !important
}

.pr-24 {
   padding-right: 24px !important
}

.pr-25 {
   padding-right: 25px !important
}

.pr-26 {
   padding-right: 26px !important
}

.pr-27 {
   padding-right: 27px !important
}

.pr-28 {
   padding-right: 28px !important
}

.pr-29 {
   padding-right: 29px !important
}

.pr-30 {
   padding-right: 30px !important
}

.pr-35 {
   padding-right: 35px !important
}

.pr-36 {
   padding-right: 36px !important
}

.pr-37 {
   padding-right: 37px !important
}

.pr-38 {
   padding-right: 38px !important
}

.pr-39 {
   padding-right: 39px !important
}

.pr-40 {
   padding-right: 40px !important
}

.m-0 {
   margin: 0 !important
}

.m-1 {
   margin: 1px !important
}

.m-2 {
   margin: 2px !important
}

.m-3 {
   margin: 3px !important
}

.m-4 {
   margin: 4px !important
}

.m-5 {
   margin: 5px !important
}

.m-6 {
   margin: 6px !important
}

.m-7 {
   margin: 7px !important
}

.m-8 {
   margin: 8px !important
}

.m-9 {
   margin: 9px !important
}

.m-10 {
   margin: 10px !important
}

.m-11 {
   margin: 11px !important
}

.m-12 {
   margin: 12px !important
}

.m-13 {
   margin: 13px !important
}

.m-14 {
   margin: 14px !important
}

.m-15 {
   margin: 15px !important
}

.m-16 {
   margin: 16px !important
}

.m-17 {
   margin: 17px !important
}

.m-18 {
   margin: 18px !important
}

.m-19 {
   margin: 19px !important
}

.m-20 {
   margin: 20px !important
}

.m-21 {
   margin: 21px !important
}

.m-22 {
   margin: 22px !important
}

.m-23 {
   margin: 23px !important
}

.m-24 {
   margin: 24px !important
}

.m-25 {
   margin: 25px !important
}

.m-26 {
   margin: 26px !important
}

.m-27 {
   margin: 27px !important
}

.m-28 {
   margin: 28px !important
}

.m-29 {
   margin: 29px !important
}

.m-30 {
   margin: 30px !important
}

.m-35 {
   margin: 35px !important
}

.m-36 {
   margin: 36px !important
}

.m-37 {
   margin: 37px !important
}

.m-38 {
   margin: 38px !important
}

.m-39 {
   margin: 39px !important
}

.m-40 {
   margin: 40px !important
}

.mt-0 {
   margin-top: 0 !important
}

.mt-1 {
   margin-top: 1px !important
}

.mt-2 {
   margin-top: 2px !important
}

.mt-3 {
   margin-top: 3px !important
}

.mt-4 {
   margin-top: 4px !important
}

.mt-5 {
   margin-top: 5px !important
}

.mt-6 {
   margin-top: 6px !important
}

.mt-7 {
   margin-top: 7px !important
}

.mt-8 {
   margin-top: 8px !important
}

.mt-9 {
   margin-top: 9px !important
}

.mt-10 {
   margin-top: 10px !important
}

.mt-11 {
   margin-top: 11px !important
}

.mt-12 {
   margin-top: 12px !important
}

.mt-13 {
   margin-top: 13px !important
}

.mt-14 {
   margin-top: 14px !important
}

.mt-15 {
   margin-top: 15px !important
}

.mt-16 {
   margin-top: 16px !important
}

.mt-17 {
   margin-top: 17px !important
}

.mt-18 {
   margin-top: 18px !important
}

.mt-19 {
   margin-top: 19px !important
}

.mt-20 {
   margin-top: 20px !important
}

.mt-21 {
   margin-top: 21px !important
}

.mt-22 {
   margin-top: 22px !important
}

.mt-23 {
   margin-top: 23px !important
}

.mt-24 {
   margin-top: 24px !important
}

.mt-25 {
   margin-top: 25px !important
}

.mt-26 {
   margin-top: 26px !important
}

.mt-27 {
   margin-top: 27px !important
}

.mt-28 {
   margin-top: 28px !important
}

.mt-29 {
   margin-top: 29px !important
}

.mt-30 {
   margin-top: 30px !important
}

.mt-35 {
   margin-top: 35px !important
}

.mt-36 {
   margin-top: 36px !important
}

.mt-37 {
   margin-top: 37px !important
}

.mt-38 {
   margin-top: 38px !important
}

.mt-39 {
   margin-top: 39px !important
}

.mt-40 {
   margin-top: 40px !important
}

.mb-0 {
   margin-bottom: 0 !important
}

.mb-1 {
   margin-bottom: 1px !important
}

.mb-2 {
   margin-bottom: 2px !important
}

.mb-3 {
   margin-bottom: 3px !important
}

.mb-4 {
   margin-bottom: 4px !important
}

.mb-5 {
   margin-bottom: 5px !important
}

.mb-6 {
   margin-bottom: 6px !important
}

.mb-7 {
   margin-bottom: 7px !important
}

.mb-8 {
   margin-bottom: 8px !important
}

.mb-9 {
   margin-bottom: 9px !important
}

.mb-10 {
   margin-bottom: 10px !important
}

.mb-11 {
   margin-bottom: 11px !important
}

.mb-12 {
   margin-bottom: 12px !important
}

.mb-13 {
   margin-bottom: 13px !important
}

.mb-14 {
   margin-bottom: 14px !important
}

.mb-15 {
   margin-bottom: 15px !important
}

.mb-16 {
   margin-bottom: 16px !important
}

.mb-17 {
   margin-bottom: 17px !important
}

.mb-18 {
   margin-bottom: 18px !important
}

.mb-19 {
   margin-bottom: 19px !important
}

.mb-20 {
   margin-bottom: 20px !important
}

.mb-21 {
   margin-bottom: 21px !important
}

.mb-22 {
   margin-bottom: 22px !important
}

.mb-23 {
   margin-bottom: 23px !important
}

.mb-24 {
   margin-bottom: 24px !important
}

.mb-25 {
   margin-bottom: 25px !important
}

.mb-26 {
   margin-bottom: 26px !important
}

.mb-27 {
   margin-bottom: 27px !important
}

.mb-28 {
   margin-bottom: 28px !important
}

.mb-29 {
   margin-bottom: 29px !important
}

.mb-30 {
   margin-bottom: 30px !important
}

.mb-35 {
   margin-bottom: 35px !important
}

.mb-36 {
   margin-bottom: 36px !important
}

.mb-37 {
   margin-bottom: 37px !important
}

.mb-38 {
   margin-bottom: 38px !important
}

.mb-39 {
   margin-bottom: 39px !important
}

.mb-40 {
   margin-bottom: 40px !important
}

.ml-0 {
   margin-left: 0 !important
}

.ml-1 {
   margin-left: 1px !important
}

.ml-2 {
   margin-left: 2px !important
}

.ml-3 {
   margin-left: 3px !important
}

.ml-4 {
   margin-left: 4px !important
}

.ml-5 {
   margin-left: 5px !important
}

.ml-6 {
   margin-left: 6px !important
}

.ml-7 {
   margin-left: 7px !important
}

.ml-8 {
   margin-left: 8px !important
}

.ml-9 {
   margin-left: 9px !important
}

.ml-10 {
   margin-left: 10px !important
}

.ml-11 {
   margin-left: 11px !important
}

.ml-12 {
   margin-left: 12px !important
}

.ml-13 {
   margin-left: 13px !important
}

.ml-14 {
   margin-left: 14px !important
}

.ml-15 {
   margin-left: 15px !important
}

.ml-16 {
   margin-left: 16px !important
}

.ml-17 {
   margin-left: 17px !important
}

.ml-18 {
   margin-left: 18px !important
}

.ml-19 {
   margin-left: 19px !important
}

.ml-20 {
   margin-left: 20px !important
}

.ml-21 {
   margin-left: 21px !important
}

.ml-22 {
   margin-left: 22px !important
}

.ml-23 {
   margin-left: 23px !important
}

.ml-24 {
   margin-left: 24px !important
}

.ml-25 {
   margin-left: 25px !important
}

.ml-26 {
   margin-left: 26px !important
}

.ml-27 {
   margin-left: 27px !important
}

.ml-28 {
   margin-left: 28px !important
}

.ml-29 {
   margin-left: 29px !important
}

.ml-30 {
   margin-left: 30px !important
}

.ml-35 {
   margin-left: 35px !important
}

.ml-36 {
   margin-left: 36px !important
}

.ml-37 {
   margin-left: 37px !important
}

.ml-38 {
   margin-left: 38px !important
}

.ml-39 {
   margin-left: 39px !important
}

.ml-40 {
   margin-left: 40px !important
}

.mr-0 {
   margin-right: 0 !important
}

.mr-1 {
   margin-right: 1px !important
}

.mr-2 {
   margin-right: 2px !important
}

.mr-3 {
   margin-right: 3px !important
}

.mr-4 {
   margin-right: 4px !important
}

.mr-5 {
   margin-right: 5px !important
}

.mr-6 {
   margin-right: 6px !important
}

.mr-7 {
   margin-right: 7px !important
}

.mr-8 {
   margin-right: 8px !important
}

.mr-9 {
   margin-right: 9px !important
}

.mr-10 {
   margin-right: 10px !important
}

.mr-11 {
   margin-right: 11px !important
}

.mr-12 {
   margin-right: 12px !important
}

.mr-13 {
   margin-right: 13px !important
}

.mr-14 {
   margin-right: 14px !important
}

.mr-15 {
   margin-right: 15px !important
}

.mr-16 {
   margin-right: 16px !important
}

.mr-17 {
   margin-right: 17px !important
}

.mr-18 {
   margin-right: 18px !important
}

.mr-19 {
   margin-right: 19px !important
}

.mr-20 {
   margin-right: 20px !important
}

.mr-21 {
   margin-right: 21px !important
}

.mr-22 {
   margin-right: 22px !important
}

.mr-23 {
   margin-right: 23px !important
}

.mr-24 {
   margin-right: 24px !important
}

.mr-25 {
   margin-right: 25px !important
}

.mr-26 {
   margin-right: 26px !important
}

.mr-27 {
   margin-right: 27px !important
}

.mr-28 {
   margin-right: 28px !important
}

.mr-29 {
   margin-right: 29px !important
}

.mr-30 {
   margin-right: 30px !important
}

.mr-35 {
   margin-right: 35px !important
}

.mr-36 {
   margin-right: 36px !important
}

.mr-37 {
   margin-right: 37px !important
}

.mr-38 {
   margin-right: 38px !important
}

.mr-39 {
   margin-right: 39px !important
}

.mr-40 {
   margin-right: 40px !important
}

.px-0 {
   padding-left: 0 !important;
   padding-right: 0 !important
}

.px-1 {
   padding-left: 1px !important;
   padding-right: 1px !important
}

.px-2 {
   padding-left: 2px !important;
   padding-right: 2px !important
}

.px-3 {
   padding-left: 3px !important;
   padding-right: 3px !important
}

.px-4 {
   padding-left: 4px !important;
   padding-right: 4px !important
}

.px-5 {
   padding-left: 5px !important;
   padding-right: 5px !important
}

.px-6 {
   padding-left: 6px !important;
   padding-right: 6px !important
}

.px-7 {
   padding-left: 7px !important;
   padding-right: 7px !important
}

.px-8 {
   padding-left: 8px !important;
   padding-right: 8px !important
}

.px-9 {
   padding-left: 9px !important;
   padding-right: 9px !important
}

.px-10 {
   padding-left: 10px !important;
   padding-right: 10px !important
}

.px-11 {
   padding-left: 11px !important;
   padding-right: 11px !important
}

.px-12 {
   padding-left: 12px !important;
   padding-right: 12px !important
}

.px-13 {
   padding-left: 13px !important;
   padding-right: 13px !important
}

.px-14 {
   padding-left: 14px !important;
   padding-right: 14px !important
}

.px-15 {
   padding-left: 15px !important;
   padding-right: 15px !important
}

.px-16 {
   padding-left: 16px !important;
   padding-right: 16px !important
}

.px-17 {
   padding-left: 17px !important;
   padding-right: 17px !important
}

.px-18 {
   padding-left: 18px !important;
   padding-right: 18px !important
}

.px-19 {
   padding-left: 19px !important;
   padding-right: 19px !important
}

.px-20 {
   padding-left: 20px !important;
   padding-right: 20px !important
}

.px-21 {
   padding-left: 21px !important;
   padding-right: 21px !important
}

.px-22 {
   padding-left: 22px !important;
   padding-right: 22px !important
}

.px-23 {
   padding-left: 23px !important;
   padding-right: 23px !important
}

.px-24 {
   padding-left: 24px !important;
   padding-right: 24px !important
}

.px-25 {
   padding-left: 25px !important;
   padding-right: 25px !important
}

.px-26 {
   padding-left: 26px !important;
   padding-right: 26px !important
}

.px-27 {
   padding-left: 27px !important;
   padding-right: 27px !important
}

.px-28 {
   padding-left: 28px !important;
   padding-right: 28px !important
}

.px-29 {
   padding-left: 29px !important;
   padding-right: 29px !important
}

.px-30 {
   padding-left: 30px !important;
   padding-right: 30px !important
}

.px-35 {
   padding-left: 35px !important;
   padding-right: 35px !important
}

.py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important
}

.py-1 {
   padding-top: 1px !important;
   padding-bottom: 1px !important
}

.py-2 {
   padding-top: 2px !important;
   padding-bottom: 2px !important
}

.py-3 {
   padding-top: 3px !important;
   padding-bottom: 3px !important
}

.py-4 {
   padding-top: 4px !important;
   padding-bottom: 4px !important
}

.py-5 {
   padding-top: 5px !important;
   padding-bottom: 5px !important
}

.py-6 {
   padding-top: 6px !important;
   padding-bottom: 6px !important
}

.py-7 {
   padding-top: 7px !important;
   padding-bottom: 7px !important
}

.py-8 {
   padding-top: 8px !important;
   padding-bottom: 8px !important
}

.py-9 {
   padding-top: 9px !important;
   padding-bottom: 9px !important
}

.py-10 {
   padding-top: 10px !important;
   padding-bottom: 10px !important
}

.py-11 {
   padding-top: 11px !important;
   padding-bottom: 11px !important
}

.py-12 {
   padding-top: 12px !important;
   padding-bottom: 12px !important
}

.py-13 {
   padding-top: 13px !important;
   padding-bottom: 13px !important
}

.py-14 {
   padding-top: 14px !important;
   padding-bottom: 14px !important
}

.py-15 {
   padding-top: 15px !important;
   padding-bottom: 15px !important
}

.py-16 {
   padding-top: 16px !important;
   padding-bottom: 16px !important
}

.py-17 {
   padding-top: 17px !important;
   padding-bottom: 17px !important
}

.py-18 {
   padding-top: 18px !important;
   padding-bottom: 18px !important
}

.py-19 {
   padding-top: 19px !important;
   padding-bottom: 19px !important
}

.py-20 {
   padding-top: 20px !important;
   padding-bottom: 20px !important
}

.py-21 {
   padding-top: 21px !important;
   padding-bottom: 21px !important
}

.py-22 {
   padding-top: 22px !important;
   padding-bottom: 22px !important
}

.py-23 {
   padding-top: 23px !important;
   padding-bottom: 23px !important
}

.py-24 {
   padding-top: 24px !important;
   padding-bottom: 24px !important
}

.py-25 {
   padding-top: 25px !important;
   padding-bottom: 25px !important
}

.py-26 {
   padding-top: 26px !important;
   padding-bottom: 26px !important
}

.py-27 {
   padding-top: 27px !important;
   padding-bottom: 27px !important
}

.py-28 {
   padding-top: 28px !important;
   padding-bottom: 28px !important
}

.py-29 {
   padding-top: 29px !important;
   padding-bottom: 29px !important
}

.py-30 {
   padding-top: 30px !important;
   padding-bottom: 30px !important
}

.py-35 {
   padding-top: 35px !important;
   padding-bottom: 35px !important
}

.mx-0 {
   margin-left: 0 !important;
   margin-right: 0 !important
}

.mx-1 {
   margin-left: 1px !important;
   margin-right: 1px !important
}

.mx-2 {
   margin-left: 2px !important;
   margin-right: 2px !important
}

.mx-3 {
   margin-left: 3px !important;
   margin-right: 3px !important
}

.mx-4 {
   margin-left: 4px !important;
   margin-right: 4px !important
}

.mx-5 {
   margin-left: 5px !important;
   margin-right: 5px !important
}

.mx-6 {
   margin-left: 6px !important;
   margin-right: 6px !important
}

.mx-7 {
   margin-left: 7px !important;
   margin-right: 7px !important
}

.mx-8 {
   margin-left: 8px !important;
   margin-right: 8px !important
}

.mx-9 {
   margin-left: 9px !important;
   margin-right: 9px !important
}

.mx-10 {
   margin-left: 10px !important;
   margin-right: 10px !important
}

.mx-11 {
   margin-left: 11px !important;
   margin-right: 11px !important
}

.mx-12 {
   margin-left: 12px !important;
   margin-right: 12px !important
}

.mx-13 {
   margin-left: 13px !important;
   margin-right: 13px !important
}

.mx-14 {
   margin-left: 14px !important;
   margin-right: 14px !important
}

.mx-15 {
   margin-left: 15px !important;
   margin-right: 15px !important
}

.mx-16 {
   margin-left: 16px !important;
   margin-right: 16px !important
}

.mx-17 {
   margin-left: 17px !important;
   margin-right: 17px !important
}

.mx-18 {
   margin-left: 18px !important;
   margin-right: 18px !important
}

.mx-19 {
   margin-left: 19px !important;
   margin-right: 19px !important
}

.mx-20 {
   margin-left: 20px !important;
   margin-right: 20px !important
}

.mx-21 {
   margin-left: 21px !important;
   margin-right: 21px !important
}

.mx-22 {
   margin-left: 22px !important;
   margin-right: 22px !important
}

.mx-23 {
   margin-left: 23px !important;
   margin-right: 23px !important
}

.mx-24 {
   margin-left: 24px !important;
   margin-right: 24px !important
}

.mx-25 {
   margin-left: 25px !important;
   margin-right: 25px !important
}

.mx-26 {
   margin-left: 26px !important;
   margin-right: 26px !important
}

.mx-27 {
   margin-left: 27px !important;
   margin-right: 27px !important
}

.mx-28 {
   margin-left: 28px !important;
   margin-right: 28px !important
}

.mx-29 {
   margin-left: 29px !important;
   margin-right: 29px !important
}

.mx-30 {
   margin-left: 30px !important;
   margin-right: 30px !important
}

.mx-35 {
   margin-left: 35px !important;
   margin-right: 35px !important
}

.my-0 {
   margin-top: 0 !important;
   margin-bottom: 0 !important
}

.my-1 {
   margin-top: 1px !important;
   margin-bottom: 1px !important
}

.my-2 {
   margin-top: 2px !important;
   margin-bottom: 2px !important
}

.my-3 {
   margin-top: 3px !important;
   margin-bottom: 3px !important
}

.my-4 {
   margin-top: 4px !important;
   margin-bottom: 4px !important
}

.my-5 {
   margin-top: 5px !important;
   margin-bottom: 5px !important
}

.my-6 {
   margin-top: 6px !important;
   margin-bottom: 6px !important
}

.my-7 {
   margin-top: 7px !important;
   margin-bottom: 7px !important
}

.my-8 {
   margin-top: 8px !important;
   margin-bottom: 8px !important
}

.my-9 {
   margin-top: 9px !important;
   margin-bottom: 9px !important
}

.my-10 {
   margin-top: 10px !important;
   margin-bottom: 10px !important
}

.my-11 {
   margin-top: 11px !important;
   margin-bottom: 11px !important
}

.my-12 {
   margin-top: 12px !important;
   margin-bottom: 12px !important
}

.my-13 {
   margin-top: 13px !important;
   margin-bottom: 13px !important
}

.my-14 {
   margin-top: 14px !important;
   margin-bottom: 14px !important
}

.my-15 {
   margin-top: 15px !important;
   margin-bottom: 15px !important
}

.my-16 {
   margin-top: 16px !important;
   margin-bottom: 16px !important
}

.my-17 {
   margin-top: 17px !important;
   margin-bottom: 17px !important
}

.my-18 {
   margin-top: 18px !important;
   margin-bottom: 18px !important
}

.my-19 {
   margin-top: 19px !important;
   margin-bottom: 19px !important
}

.my-20 {
   margin-top: 20px !important;
   margin-bottom: 20px !important
}

.my-21 {
   margin-top: 21px !important;
   margin-bottom: 21px !important
}

.my-22 {
   margin-top: 22px !important;
   margin-bottom: 22px !important
}

.my-23 {
   margin-top: 23px !important;
   margin-bottom: 23px !important
}

.my-24 {
   margin-top: 24px !important;
   margin-bottom: 24px !important
}

.my-25 {
   margin-top: 25px !important;
   margin-bottom: 25px !important
}

.my-26 {
   margin-top: 26px !important;
   margin-bottom: 26px !important
}

.my-27 {
   margin-top: 27px !important;
   margin-bottom: 27px !important
}

.my-28 {
   margin-top: 28px !important;
   margin-bottom: 28px !important
}

.my-29 {
   margin-top: 29px !important;
   margin-bottom: 29px !important
}

.my-30 {
   margin-top: 30px !important;
   margin-bottom: 30px !important
}

.my-35 {
   margin-top: 35px !important;
   margin-bottom: 35px !important
}

.hint-text {
   font-size: 11px;
   opacity: .8;
   margin: 5px 0;
   font-style: italic
}

.hidden {
   display: none
}

.bg-white {
   background-color: #fff
}

.p-sticky {
   position: sticky !important;
   z-index: 99;
   top: 0
}

.p-fixed-bottom {
   position: fixed !important;
   z-index: 99;
   bottom: 0;
   width: 100%
}

.full-height {
   min-height: 100vh
}

.light {
   font-weight: lighter
}

.bold {
   font-weight: 700
}

.bolder {
   font-weight: bolder
}

.link-unstyle,
.link-unstyle:active,
.link-unstyle:focus,
.link-unstyle:hover {
   text-decoration: none
}

.lh-25 {
   line-height: 25px
}

.lh-26 {
   line-height: 26px
}

.lh-27 {
   line-height: 27px
}

.lh-28 {
   line-height: 28px
}

.lh-29 {
   line-height: 29px
}

.lh-30 {
   line-height: 30px
}

.lh-31 {
   line-height: 31px
}

.lh-32 {
   line-height: 32px
}

.lh-33 {
   line-height: 33px
}

.lh-34 {
   line-height: 34px
}

.lh-35 {
   line-height: 35px
}

.lh-36 {
   line-height: 36px
}

.lh-37 {
   line-height: 37px
}

.lh-38 {
   line-height: 38px
}

.lh-39 {
   line-height: 39px
}

.lh-40 {
   line-height: 40px
}

.inline {
   display: inline
}

.inline-block {
   display: inline-block
}

.block {
   display: block
}

.no-border {
   border: none !important
}

.no-shadow {
   box-shadow: none !important
}

.text-underline {
   text-decoration: underline
}

.text-overflow {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis
}

a.disabled {
   cursor: not-allowed
}

.full-width {
   width: 100%;
}

.text-link {
   cursor: pointer;
   color: #0000aa;
}